import ProfileImageBoy from "./assets/profile_boy.png";
import ProfileImageGirl from "./assets/profile_girl.png";
function Chat() {
  return (
    <section className="nes-container is-centered is-rounded is-dark">
      <h3>Pick your starter!</h3>

      <section className="message-list">
        <section className="message-left">
          <img
            draggable="false"
            src={ProfileImageGirl}
            alt="Stephanie says"
            className="pixelated"
          />

          <div className="nes-balloon from-left">
            <p>Would you like to join me for a beverage or lunch today?</p>
          </div>
        </section>

        <section className="message-right">
          <div className="nes-balloon from-right">
            <p>
              Sure, I'm down for grabbing a coffee or drink or lunch! It gets
              rainy in the afternoon, so the earlier the better.
            </p>
          </div>
          <img
            draggable="false"
            src={ProfileImageBoy}
            alt="Max says"
            className="pixelated"
          />
        </section>

        <section className="message-left">
          <img
            draggable="false"
            src={ProfileImageGirl}
            alt="Stephanie says"
            className="pixelated"
          />

          <div className="nes-balloon from-left">
            <p>
              <a
                href="https://www.lochergut.net/"
                target="_blank"
                rel="noreferrer"
              >
                Cafe Lochergut
              </a>{" "}
              at 1?
            </p>
          </div>
        </section>

        <section className="message-right">
          <div className="nes-balloon from-right">
            <p>Works, see you soon!</p>
          </div>
          <img
            draggable="false"
            src={ProfileImageBoy}
            alt="Max says"
            className="pixelated"
          />
        </section>
      </section>
      <div>
        <i className="nes-icon is-large star"></i>
        <i className="nes-icon is-large star"></i>
        <i className="nes-icon is-large star"></i>
      </div>
    </section>
  );
}

export default Chat;
