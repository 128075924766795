import "./App.scss";
import Title from "./Title";

import PhotoAlbum, { PortalContainer } from "./PhotoAlbum";
import Starter from "./Starter";
import Chat from "./Chat";
import Wave from "./Wave";
import Activities from "./Activities";
import Thanks from "./Thanks";

function App() {
  return (
    <>
      <Title></Title>
      <main className="section-background bg-green-transition is-dark gap">
        <div className="section-centered">
          <h1 id="2020">
            <a href="#2020">#</a>The day we met: May 23, 2020
          </h1>
          <Chat></Chat>
        </div>

        <PhotoAlbum></PhotoAlbum>

        <Starter></Starter>

        <Wave></Wave>
      </main>

      <main className="section-background bg-blue is-dark">
        <div className="section-centered">
          <h1 id="2022">
            <a href="#2022">#</a> 2022
          </h1>
          <section className="nes-container is-centered is-rounded is-dark">
            <p>
              We are celebrating our marriage with you on August 12 &amp; 13!
            </p>
            <a
              href="https://forms.gle/N2vbXKFQ1oKaAHBDA"
              className="nes-btn is-success"
              target="_blank"
              rel="noreferrer"
            >
              RSVP
            </a>
          </section>
          <div className="nes-table-responsive">
            <table className="nes-table is-dark is-bordered is-centered">
              <tbody>
                <tr>
                  <td>
                    <strong>Friday Aug&nbsp;12</strong>
                  </td>
                  <td>
                    <p>Wedding Celebration at Badi Richterswil.</p>
                    <p>Dress code: Summer dress, hawaiian shirt.</p>
                    <p>
                      Bring: Swim wear, towel, water/beach game if you have.
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>15:00</td>
                  <td>Meet at Bürkliplatz, Zurich</td>
                </tr>
                <tr>
                  <td>15:20</td>
                  <td>Boat ride from Bürkliplatz to Badi Richterswil</td>
                </tr>
                <tr>
                  <td>17:00</td>
                  <td>
                    Apero, water activities &amp; games at Badi Richterswil.
                  </td>
                </tr>
                <tr>
                  <td>20:00</td>
                  <td>Dinner: Tavolata at Badi Richterswil</td>
                </tr>
                <tr>
                  <td>Flexible</td>
                  <td>Train from Richterswil to Zurich</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="nes-table-responsive">
            <table className="nes-table is-dark is-bordered is-centered">
              <tbody>
                <tr>
                  <td>
                    <strong>Saturday Aug&nbsp;13</strong>
                  </td>
                  <td>
                    <p>
                      <a
                        href="https://www.streetparade.com/en/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Street Parade
                      </a>{" "}
                      in Zurich
                    </p>
                    <p>
                      Dress code: Summer rave outfit with solid shoes and a hat.
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>13:00</td>
                  <td>Meet at Bürkliplatz, Zurich</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="nes-table-responsive">
            <table className="nes-table is-dark is-bordered is-centered">
              <tbody>
                <tr>
                  <td>
                    <strong>Sunday&nbsp;&nbsp; Aug&nbsp;14</strong>
                  </td>
                  <td>Spontaneous brunch &amp; floating</td>
                </tr>
              </tbody>
            </table>
          </div>
          <h1 id="presents">
            <a href="#presents">#</a> Presents
          </h1>
          <p>
            No need to bring physical presents. We're just looking forward to
            celebrate with you! If you'd like to contribute, you can donate to
            our honeymoon fund via:
          </p>
          <p>
            <a
              href="https://revolut.me/maxvo"
              target="_blank"
              rel="noreferrer"
              className="nes-btn is-primary"
            >
              Revolut (@maxvo)
            </a>{" "}
            <a
              href="https://paypal.me/maxvogler"
              target="_blank"
              rel="noreferrer"
              className="nes-btn is-primary"
            >
              PayPal (maxvogler)
            </a>
          </p>
          <p>
            Alternatively, bank transfer with reference "honeymoon" to:
            <br />
            Name: Max Vogler
            <br />
            IBAN: DE32500105175414687156
            <br />
            BIC:  INGDDEFFXXX
          </p>
          <h1 id="places">
            <a href="#places">#</a> Our favorite places
          </h1>
          <p>
            First time in Zurich or looking for something to do before or after
            the wedding? Here are some of our favorite places in the city:
          </p>
          <Activities />
        </div>
      </main>

      <Thanks />

      <div className="footer-placeholder"></div>
      <PortalContainer />
    </>
  );
}

export default App;
