import FrameImage from "./assets/thanks_frame.png";
import Photo from "./assets/thanks_photo.jpg";

export default function Thanks() {
  return (
    <div className="thanks-container" id="thanks">
      <img src={FrameImage} className="frame pixelated" alt="" />
      <div className="content">
        <p>
          We're still on cloud 9 from the amazing wedding celebration we shared
          with you. Thank you so much for coming from near and far to celebrate
          our love. We really appreciate your contributions to our honeymoon
          fund, your gifts, and most importantly your time.
        </p>
        <p>
          We hope you had fun (
          <a
            href="https://photos.app.goo.gl/kem1JGvtHi1dnthX8"
            target="_blank"
            rel="noreferrer"
            title="View photo album"
          >
            share your photos
          </a>
          ), and look forward to seeing you all in the many more milestones of
          our (now legally) conjoined lives.
        </p>
        <p>– Steph &amp; Max Vogler</p>
      </div>
      <a
        href="https://photos.app.goo.gl/kem1JGvtHi1dnthX8"
        target="_blank"
        rel="noreferrer"
        title="View photo album"
      >
        <img src={Photo} className="thanks-photo" alt="" />
      </a>
    </div>
  );
}
