import TitleBgImage from "./assets/title_bg.png";
import TitleBoyImage from "./assets/title_boy.png";
import TitleGirlImage from "./assets/title_girl.png";
import TitleGrassImage from "./assets/title_grass.png";

function Title() {
  return (
    <section className="title-screen pixelated">
      <img src={TitleBgImage} alt="" className="bg" />
      <div className="nes-container is-rounded is-centered">
        <h3>I choose you!</h3>
        Stephanie Skolkin <br /> &amp; Max Vogler
      </div>
      <div className="characters">
        <img src={TitleGirlImage} alt="" className="girl" />
        <img src={TitleBoyImage} alt="" className="boy" />
      </div>
      <div className="grass">
        <img src={TitleGrassImage} alt="" />
        <img src={TitleGrassImage} alt="" />
      </div>
      <div className="grass scrolling">
        <img src={TitleGrassImage} alt="" />
        <img src={TitleGrassImage} alt="" />
      </div>
    </section>
  );
}

export default Title;
