import StarterBg from "./assets/starter_bg.png";
import BagImage from "./assets/starter_bag.png";
import BallImage from "./assets/starter_ball.gif";
import { useEffect, useRef, useState } from "react";

function Starter() {
  const ref = useRef<HTMLElement | null>(null);
  const [textboxVisible, setTextboxVisible] = useState(false);

  useEffect(() => {
    const current = ref.current;
    if (!current) {
      return;
    }

    const observer = new IntersectionObserver(
      ([entry]) => {
        setTextboxVisible(entry.isIntersecting);
      },
      { threshold: 0.9 }
    );
    observer.observe(current);

    return () => {
      observer.unobserve(current);
    };
  }, [ref]);

  return (
    <section ref={ref} className="starter-container pixelated">
      <img src={StarterBg} alt="" className="bg" />
      <img src={BagImage} alt="" className="bag" />
      <img src={BallImage} alt="" className="ball" />

      <div className={`popup-message ${textboxVisible ? "" : "hidden"}`}>
        <section className={`nes-container is-centered bg-black is-dark`}>
          <h3>We're getting married!</h3>
        </section>
      </div>
    </section>
  );
}

export default Starter;
