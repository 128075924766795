import {
  MouseEventHandler,
  PropsWithChildren,
  useEffect,
  useRef,
  useState,
} from "react";
import { createPortal } from "react-dom";
import PhotoAlbumImage from "./assets/album_half.png";

import Image1 from "./assets/photos/00100srPORTRAIT_00100_BURST20200830124821535_COVER.jpg";
import Image2 from "./assets/photos/00100trPORTRAIT_00100_BURST20200704212010822_COVER.jpg";
import Image3 from "./assets/photos/IMG_20200703_223628.jpg";
import Image4 from "./assets/photos/MVIMG_20200823_151051.jpg";
import Image5 from "./assets/photos/MVIMG_20200924_140540.jpg";
import Image6 from "./assets/photos/MVIMG_20200926_193616.jpg";
import Image7 from "./assets/photos/PXL_20201114_144310226.jpg";
import Image8 from "./assets/photos/PXL_20210405_131017944.MP.jpg";
import Image9 from "./assets/photos/PXL_20210413_163722090.MP.jpg";
import Image10 from "./assets/photos/PXL_20210508_082456584.MP.jpg";
// import Image11 from "./assets/photos/PXL_20211023_093158950.MP.jpg";
// import Image from "./assets/photos/PXL_20211224_172315268.MP.jpg";
const Image11 = "/photos/PXL_20220417_152609819.MP-CINEMATIC.mp4";
// import Image from "./assets/photos/PXL_20220430_133511626.MP.jpg";
const Image12 = "/photos/PXL_20220612_164830862_scaled.mov";
// import Image from "./assets/photos/PXL_20220626_105729541.jpg";

const IMAGES = [
  Image1,
  Image2,
  Image3,
  Image4,
  Image5,
  Image6,
  Image7,
  Image8,
  Image9,
  Image10,
  Image11,
  Image12,
];

function PhotoAlbum() {
  const [selectedImage, selectImage] = useState<string | undefined>();

  return (
    <>
      <section className="photo-album pixelated">
        <div className="left">
          <img src={PhotoAlbumImage} alt="" className="bg" />

          <div className="photos">
            {IMAGES.slice(0, 6).map((img) => (
              <Photo key={img} src={img} onClick={() => selectImage(img)} />
            ))}
          </div>
        </div>
        <div className="right">
          <img src={PhotoAlbumImage} alt="" className="bg" />

          <div className="photos">
            {IMAGES.slice(6).map((img) => (
              <Photo key={img} src={img} onClick={() => selectImage(img)} />
            ))}
          </div>
        </div>
      </section>

      {selectedImage ? (
        <Portal>
          <div className="dialog" onClick={() => selectImage(undefined)}>
            <Photo src={selectedImage} onClick={() => selectImage(undefined)} />
          </div>
        </Portal>
      ) : null}
    </>
  );
}

function Photo({
  src,
  onClick,
}: {
  src: string;
  onClick: MouseEventHandler<HTMLElement>;
}) {
  return (
    <div onClick={onClick}>
      {src.endsWith(".jpg") ? (
        <img src={src} alt="" />
      ) : (
        <video autoPlay loop muted controls={false} src={src} />
      )}
    </div>
  );
}

export const Portal = (props: PropsWithChildren<{}>) => {
  const el = useRef(document.createElement("div"));
  useEffect(() => {
    const { current } = el;
    const portal = document.getElementById("portal");
    portal!.appendChild(current);
    return () => {
      portal!.removeChild(current);
    };
  }, [props.children]);
  return createPortal(props.children, el.current);
};

export const PortalContainer = () => <div id="portal"></div>;

export default PhotoAlbum;
