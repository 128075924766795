import WaveImage from "./assets/wave.png";

function Wave() {
  return (
    <div className="wave-container">
      <img src={WaveImage} className="wave pixelated" alt="" />
      <div className="bg-blue"></div>
    </div>
  );
}

export default Wave;
