import { useMemo, useState } from "react";
import activities from "./activities.json";

function Activities() {
  const categories = useMemo(
    () => Object.keys(activities) as Array<keyof typeof activities>,
    []
  );
  const [activeCategory, setActiveCategory] = useState(categories[0]);

  return (
    <>
      <div className="nes-container is-centered is-rounded is-dark radio-row">
        {categories.map((category) => (
          <label key={category}>
            <input
              type="radio"
              name="category"
              className="nes-radio is-dark"
              checked={activeCategory === category}
              onChange={() => setActiveCategory(category)}
            />
            <span>{category}</span>
          </label>
        ))}
      </div>
      <ul className="nes-list is-circle is-dark">
        {Object.entries(activities[activeCategory]).map(
          ([name, description]) => (
            <li key={name}>
              <strong>{name}</strong>: {description}
            </li>
          )
        )}
      </ul>
    </>
  );
}

export default Activities;
